import { Suspense } from "react";

import Head from "next/head";

import { Provider } from "react-redux";

import { CssBaseline } from "@mui/material";

import { ThemeProvider } from "@mui/material/styles";

import { LocalizationProvider } from "@mui/x-date-pickers";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { AuthConsumer, AuthProvider } from "contexts/auth-context";

import { useNProgress } from "hooks/use-nprogress";

import { createTheme } from "theme/index";

import { SplashScreenImage } from "components/splash-screen";

import { store } from "store/store";

import "../styles/index.css";
import "sweetalert2/src/sweetalert2.scss";
import "simplebar-react/dist/simplebar.min.css";


const App = (props) => {
  const { Component, pageProps } = props;

  useNProgress();

  const getLayout = Component.getLayout ?? ((page) => page);

  const theme = createTheme();

  return (
    <div>
      <Head>
        <title>Koreta</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <Suspense fallback={<SplashScreenImage />}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Provider store={store}>
            <AuthProvider>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <AuthConsumer>{() => getLayout(<Component {...pageProps} />)}</AuthConsumer>
              </ThemeProvider>
            </AuthProvider>
          </Provider>
        </LocalizationProvider>
      </Suspense>
    </div>
  );
};

export default App;
