export const SplashScreenImage = () => {
  return (
    <img
      width={"250px"}
      alt="koreta_splalogo"
      style={{ margin: "auto auto" }}
      src="/assets/koreta-splash.png"
    />
  );
};
